.selected-flag {
  width: 68px !important;
}

.react-tel-input .form-control {
  padding-left: 73px !important;
}

.arrow {
  border: none !important;
  position: absolute !important;
  left: 29px !important;
}

.arrow:after {
  content: "" !important;
  background-image: url(../public/images/arrow.svg) !important;
  background-repeat: no-repeat;
  height: 8.37px !important;
  width: 10px !important;
  position: absolute !important;
  z-index: 999 !important;
}

.form-control {
  width: 100% !important;
  z-index: 10 !important;
  border-radius: 0.375rem !important;
  border: 1px solid #D1D6DB !important;
  background: white !important;
  color: #0e0e0f;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  outline: none !important;
  height: 58px !important;
}

.react-tel-input .country-list {
  max-height: 234px !important;
}

.react-tel-input {
  height: 56px !important;
}

.flag-dropdown {
  border: none !important;
  height: 54px !important;
  position: relative !important;
  top: -55px !important;
}

.selected-flag {
  z-index: 10 !important;
  position: relative !important;
}

.react-tel-input .selected-flag .arrow {
  margin-top: -4px !important;
}

.react-tel-input .flag-dropdown.open {
  z-index: auto !important;
}

.react-tel-input .selected-flag .flag {
  position: absolute;
  top: 50%;
  left: 16px;
  margin-top: -5px;
  z-index: 999 !important;
}

.react-tel-input .selected-flag {
  background-color: transparent !important;
}

.react-tel-input .country-list .search {
  top: -1px;
}

.form-group {
  position: relative !important;
  order: 1 !important;
}

.react-tel-input .country-list,
.search {
  box-shadow: none;
}

.react-tel-input .country-list {
  border: 1px solid #f2f2f7 !important;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08) !important;
}

.search-box {
  height: 56px !important;
  background: white !important;
  border: 1px solid #D1D6DB !important;
  border-radius: 8px !important;
}

.search-box::placeholder {
  font-weight: 400;
  font-size: 16px;
  position: relative;
  top: 2px;
  line-height: 24px;
  color: #8e8e93;
  font-style: normal;
  font-family: "Inter", sans-serif;
}

input.search-box {
  background-image: url(../public/images/search.svg);
  background-repeat: no-repeat;
  text-indent: 32px;
  background-position: 13px;
}



.react-tel-input .country-list .search-box {
  margin: 0 !important;
}

.react-tel-input .country-list .search {
  padding: 8px !important;
}

.selected-flag {
  width: 200px;
  height: 50px;
  position: relative;
  z-index: 1;
  background: #eee;
}

.selected-flag:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 17px;
  height: 20px;
  width: 50%; /* or 100px */
  border-right: 1px solid #0e0e0f;
}

.react-tel-input .country-list .country {
  height: 40px !important;
}

.country-list {
  display: flex;
  flex-direction: column;
  background: white !important;
  gap: 4px;
}

.form-control:focus,
input.search-box:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #D1D6DB !important;
}

.grounded-radiants {
  border: 4px solid transparent;
  border-radius: 16px;
  background: linear-gradient(
                  180deg,
                  #b57e10 0.01%,
                  #e5c35c 22.23%,
                  #f9df7b 32.81%,
                  #fff3a6 52.42%,
                  #f9df7b 73%,
                  #b57e10 100.03%
  );
  background-clip: padding-box;
  padding: 10px;
  /* just to show box-shadow still works fine */
  box-shadow: 0 3px 9px black, inset 0 0 9px white;
}

.grounded-radiants::after {
  background: linear-gradient(
                  180deg,
                  #b57e10 0.01%,
                  #e5c35c 22.23%,
                  #f9df7b 32.81%,
                  #fff3a6 52.42%,
                  #f9df7b 73%,
                  #b57e10 100.03%
  );
  content: "";
  z-index: -1;
  border-radius: 16px;
}